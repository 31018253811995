import React from "react"

import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import TrustbuildingCTA from "@src/components/TrustbuildingCTA"
import LoremIpsum from "@src/components/LoremIpsum"
import DefaultSeparator from "./_component_default_separator"

const TrustbuildingCTAPage = () => {
  const title = "Trustbuilding CTA"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <ApplyUrlProvider location={{ pathname: "/trustbuilding-cta" }}>
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <LoremIpsum />
        <TrustbuildingCTA />
        <LoremIpsum />
        <DefaultSeparator />
      </Page>
    </ApplyUrlProvider>
  )
}

export default TrustbuildingCTAPage
